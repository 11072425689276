function DummyStorage() {}

DummyStorage.prototype.getItem = function() {
  return null;
};

DummyStorage.prototype.removeItem = function() {};

DummyStorage.prototype.setItem = function() {};

export default DummyStorage;
