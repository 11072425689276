import Authentication from './authentication';
import Management from './management';
import WebAuth from './web-auth';
import version from './version';

export { Authentication, Management, WebAuth, version };

export default {
  Authentication: Authentication,
  Management: Management,
  WebAuth: WebAuth,
  version: version
};
